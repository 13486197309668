import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useRouter } from 'next/router';
import { AppRoutes } from 'src/lib/routes';
import Link from 'next/link';
import ImageWithFallback from 'src/components/ImageWithFallback';
import AboutImg from 'public/images/illu_6.png';
import { FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ClientOnlyPortal from 'src/components/ClientOnlyPortal';
import useFilter from 'src/hooks/useFilter';

type Props = {};

export const AboutItems: React.FC = () => {
  return (
    <>
      <Link href={AppRoutes.aboutRoute().toPath()}>
        <a className="tw-block tw-mb-2 tw-cursor-pointer tw-text-brand-navy hover:tw-font-semibold">
          Our Story
        </a>
      </Link>
      <Link href={AppRoutes.ourMissionRoute().toPath()}>
        <a className="tw-block tw-mb-2 tw-cursor-pointer tw-text-brand-navy hover:tw-font-semibold">
          Travel as a Force for Good
        </a>
      </Link>
      <Link href={AppRoutes.ourCarbonCommitmentRoute().toPath()}>
        <a className="tw-block tw-cursor-pointer tw-text-brand-navy hover:tw-font-semibold">
          Our Net-Zero Pledge{' '}
        </a>
      </Link>
    </>
  );
};

const AboutDropdown: React.FC<Props> = () => {
  const { isScreenMd } = useBreakpoints()
  const [show, setShow] = useState(false);
  const buttonRef = useRef(null);
  const eleRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();

  const { setShouldCloseFilterItem } = useFilter()

  useOnClickOutside(eleRef, (eventTarget) => {
    if (overlayRef.current?.contains(eventTarget)) {
      return;
    }
    setShow(false);
  });

  useEffect(() => {
    if (router.isReady) {
      router.events.on('routeChangeComplete', () => {
        setShow(false);
      });
    }
  }, [router]);

  return (
    <>
      <div ref={eleRef}>
        <div
          className="tw-cursor-pointer"
          ref={buttonRef}
          onClick={() => setShow(true)}
          onMouseEnter={() => {
            setShow(true)
            setShouldCloseFilterItem(prev => prev + 1)
          }}
          onMouseLeave={() => setShow(false)}
        >
          <span className="tw-text-brand-navy">About</span>
          <FiChevronDown size={16} className="tw-text-brand-navy" />
        </div>

        <Overlay
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -3],
                },
              },
            ],
          }}
          placement="bottom"
          target={buttonRef.current}
          show={show}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              ref={(e) => {
                props.ref(e);
                overlayRef.current = e;
              }}
              style={{
                ...props.style,
                width: 'calc(100% - 5px)'
              }}
              className="tw-pt-7 tw-z-1200"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <div className="tw-bg-brand-yellow tw-p-8 tw-font-styleSans">
                <div className="tw-text-center tw-flex tw-items-center tw-justify-center">
                  <Link href={AppRoutes.aboutRoute().toPath()}>
                    <a className="tw-block tw-cursor-pointer tw-text-brand-navy hover:tw-underline tw-font-semibold tw-text-2xl">
                      Our Story
                    </a>
                  </Link>
                  <Link href={AppRoutes.ourMissionRoute().toPath()}>
                    <a className="tw-block tw-cursor-pointer tw-text-brand-navy hover:tw-underline tw-font-semibold tw-text-2xl tw-ml-16">
                      Our Mission
                    </a>
                  </Link>
                  <Link href={AppRoutes.ourCarbonCommitmentRoute().toPath()}>
                    <a className="tw-block tw-cursor-pointer tw-text-brand-navy hover:tw-underline tw-font-semibold tw-text-2xl tw-ml-16">
                      Our Net-Zero Pledge
                    </a>
                  </Link>
                </div>

                <div className="tw-mt-8">
                  <ImageWithFallback src={AboutImg} alt="" className="tw-static tw-block tw-h-48 tw-w-auto tw-mx-auto" />
                </div>

              </div>
            </div>
          )}
        </Overlay>
      </div>

      <ClientOnlyPortal selector="#bottom-body">
        {isScreenMd ? (
          <div
            className={classNames(
              'tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen tw-z-101',
              { 'tw-hidden': !show }
            )}
            style={{ height: 'calc(100vh - 72px)' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
    </>
  );
};

export default AboutDropdown;
