import {
  Option,
  OrderItem,
  Invoice,
  DetailedTimeslot,
  EnumOptionKind
} from 'src/types';
import dayjs from 'dayjs';
import { UITimeFormat, UIDateFormat } from 'src/lib/constants';
import pluralize from 'pluralize';

export function getDurationDay(option: Option) {
  const day =
    option.durationNights && option.durationNights > 0
      ? option.durationNights
      : option.durationDays
      ? option.durationDays - 1
      : 0;
  return day < 0 ? 0 : day;
}

export const calendarPriceUnit = (orderItem: { totalQuantity: number }) => {
  if (orderItem.totalQuantity === 0) return '';
  return `for ${pluralize('x person', orderItem.totalQuantity, true)}`;
};

export function getPaymentDescription({
  order,
  orderItem,
  invoice,
}: {
  order?: {
    number?: string;
    customerName?: string;
    customerEmail?: string;
  };
  orderItem?: OrderItem;
  invoice?: Invoice;
}) {
  if (invoice && order?.number) {
    return `Topup payment for order #${order.number}`;
  } else if (orderItem && order) {
    const name = orderItem?.giftCardId
      ? `GC#${orderItem?.giftCardId}`
      : `SAO#${orderItem.supplierActivityOptionId}`;
    return order ? `${order.customerName} - ${order.customerEmail} - ${name}` : name;
  } else {
    return '';
  }
}

export const getTotalQty = (obj: {
  quantity: number;
  childQuantity: number;
  localQuantity: number;
  childLocalQuantity: number;
}) => {
  return obj.quantity + obj.childQuantity + obj.localQuantity + obj.childLocalQuantity;
};

export function displayTimeslot(data: { startTime: string; endTime: string }) {
  return `${dayjs.utc(data.startTime).format(UITimeFormat)} - ${dayjs
    .utc(data.endTime)
    .format(UITimeFormat)}`;
}

export function displayStartEndDate(data: { startDate: string; endDate?: string | null }) {
  if (data.endDate && dayjs(data.endDate).isAfter(data.startDate)) {
    return `${dayjs(data.startDate).format(UIDateFormat)} - ${dayjs(data.endDate).format(
      UIDateFormat
    )}`;
  } else {
    return dayjs(data.startDate).format(UIDateFormat);
  }
}

export function humanizeInputQtyValue(
  option: Option,
  qtyData: {
    quantity: number;
    childQuantity: number;
    localQuantity: number;
    childLocalQuantity: number;
  }
) {
  if (getTotalQty(qtyData) === 0) {
    return 'Guests';
  } else {
    let text = [] as string[];
    if (option.useChildPrices) {
      let prefix = option.hasLocalPrice ? 'Foreigner ' : '';
      if (qtyData.quantity > 0) text.push(qtyData.quantity + ' x ' + prefix + 'Adult');
      if (qtyData.childQuantity > 0) text.push(qtyData.childQuantity + ' x ' + prefix + 'Child');
      if (qtyData.localQuantity > 0) text.push(qtyData.localQuantity + ' x Local Adult');
      if (qtyData.childLocalQuantity > 0) text.push(qtyData.childLocalQuantity + ' x Local Child');
    } else {
      if (option.hasLocalPrice) {
        if (qtyData.quantity > 0)
          text.push(qtyData.quantity + ' x Foreigner' + (qtyData.quantity > 1 ? 's' : ''));
        if (qtyData.localQuantity > 0)
          text.push(qtyData.localQuantity + ' x Local' + (qtyData.localQuantity > 1 ? 's' : ''));
      } else {
        if (qtyData.quantity > 0)
          text.push(qtyData.quantity + ' x Person' + (qtyData.quantity > 1 ? 's' : ''));
      }
    }
    return text.join(', ');
  }
}

export function bgClassByOrderStatus(status: string) {
  return {
    'tw-bg-white tw-text-brand-navy tw-border tw-border-brand-green tw-border-solid': status === 'rescheduling' || status === 'processing' || status === 'awaiting',
    'tw-bg-purple tw-text-brand-navy tw-border tw-border-brand-green tw-border-solid': status === 'declined' || status === 'cancelled',
    'tw-bg-brand-green tw-text-brand-navy': status === 'completed' || status === 'confirmed',
  };
}

export function getDateAccomInfo(
  option: Option,
  bookingFormValues: {
    startDate: string | null;
    endDate: string | null;
    detailedTimeslotId: number | null;
    quantity: number;
    localQuantity: number;
    childLocalQuantity: number;
    childQuantity: number;
  },
  detailedTimeslots: DetailedTimeslot[],
  hidePrice?: boolean
) {
  const currentDetailedTimeslot = detailedTimeslots.find(
    (o) => o.id === bookingFormValues.detailedTimeslotId
  );

  return [
    !hidePrice && getTotalQty(bookingFormValues) > 0
      ? `Price ${calendarPriceUnit({
          totalQuantity: getTotalQty(bookingFormValues),
        })}`
      : null,
    bookingFormValues.startDate && dayjs(bookingFormValues.startDate).format('DD MMM'),
    bookingFormValues.endDate && dayjs(bookingFormValues.endDate).format('DD MMM'),
    !bookingFormValues.endDate &&
      bookingFormValues.startDate &&
      option.kind === EnumOptionKind.adventure &&
      getDurationDay(option) > 0 &&
      dayjs(bookingFormValues.startDate).add(getDurationDay(option), 'day').format('DD MMM'),
    bookingFormValues.startDate && bookingFormValues.endDate && option.kind === EnumOptionKind.stay
      ? pluralize(
          'night',
          dayjs(bookingFormValues.endDate).diff(bookingFormValues.startDate, 'day'),
          true
        )
      : null,
    currentDetailedTimeslot && dayjs.utc(currentDetailedTimeslot.startTime).format('HH:mm')
  ]
    .filter((v) => v)
    .join(' - ');
}
