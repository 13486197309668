import React from 'react';
import { Option, GiftCard, OrderItem, GiftExperienceCode } from 'src/types';
import { displayStartEndDate, displayTimeslot, scrollTo } from 'src/helpers';
import { truncate } from 'lodash-es';
import useConfirmDialog from 'src/hooks/useConfirmDialog';
import Link from 'next/link';
import { AppRoutes } from 'src/lib/routes';
import { useRouter } from 'next/router';
import ImageWithFallback from 'src/components/ImageWithFallback';
import ApiClient from 'src/lib/api_client';
import useCart from 'src/hooks/useCart';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import dayjs from 'dayjs';
import { UIDateFormat, UITimeFormat } from 'src/lib/constants';
import pluralize from 'pluralize';
import classNames from 'classnames';

type Props = {
  option?: Option | null;
  giftCard?: GiftCard | null;
  isCheckout?: boolean | null;
  isInsideCartPopup?: boolean | null;
  orderItem: OrderItem;
  renderAfterQuantity?: () => JSX.Element;
  giftExperienceCode?: GiftExperienceCode;
};

const PurchaseItem: React.FC<Props> = ({
  isCheckout,
  isInsideCartPopup,
  option,
  giftCard,
  orderItem,
  children,
  renderAfterQuantity,
  giftExperienceCode
}) => {
  const clearCart = useEnhanceApiClient(ApiClient.clearCart);
  const confirmDialog = useConfirmDialog();
  const router = useRouter();
  const [cart, setCart] = useCart();

  const styleText = isInsideCartPopup
    ? 'tw-font-sans tw-font-normal tw-text-sm'
    : 'tw-font-styleSans tw-font-semibold';

  return (
    <div className={classNames({"tw-bg-white tw-rounded-lg": !isInsideCartPopup})}>
      {option ? (
        <div
          className={classNames("img-as-bg tw-h-52 tw-w-full", {
            "tw-rounded-lg tw-border-2 tw-border-brand-green tw-border-solid": isInsideCartPopup,
            "tw-rounded-tl-lg tw-rounded-tr-lg": !isInsideCartPopup
          })}
          style={{ backgroundImage: `url(${option.images[0].mdUrl})` }}
        ></div>
      ) : giftCard && orderItem.giftImage ? (
        <div className="gift-card__img gift-card__img--checkout">
          <div className="tw-h-52 md:tw-h-60 tw-w-full tw-relative">
            <ImageWithFallback
              className={classNames({"tw-rounded-lg tw-border-2 tw-border-brand-green tw-border-solid": isInsideCartPopup })}
              record={orderItem.giftImage}
              imgField="webpMdUrl"
              alt=""
              fill
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <div className={classNames({
        "tw-p-4 tw-border tw-border-grey-lighter tw-border-solid tw-rounded-bl-lg tw-rounded-br-lg": !isInsideCartPopup,
        "tw-py-4": isInsideCartPopup
      })}>
        <div className={classNames("tw-pb-4 tw-mb-4", {"tw-border-b tw-border-grey-lighter tw-border-solid": !isInsideCartPopup}
        )}>
          {orderItem.isGift ? (
            <>
              {orderItem.isGiftExperience && option ? (
                <>
                  <div className="tw-mb-2 tw-font-styleSans tw-font-semibold tw-text-xl tw-truncate tw-leading-none">[GIFT] {option.name}</div>

                  {renderAfterQuantity ? renderAfterQuantity() : ''}

                  {isCheckout && (
                    <div>
                      <Link
                        href={AppRoutes.experienceRoute(
                          {
                            id: option.slugCode,
                          },
                          { gift_experience: 1 }
                        ).toUrl()}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <a className={`tw-text-brand-navy tw-underline tw-cursor-pointer ${styleText}`}>edit</a>
                      </Link>
                      <span className={`tw-mx-2 tw-text-brand-navy ${styleText}`}>|</span>
                      <span
                        className={`tw-text-brand-navy tw-underline tw-cursor-pointer ${styleText}`}
                        onClick={() => {
                          confirmDialog({
                            title: 'Cart',
                            body: 'Remove from cart?',
                            okText: 'Yes',
                            cancelText: 'No',
                          }).then(async () => {
                            const [json] = await clearCart({ cart_id: cart.id });
                            if (!('error' in json)) {
                              setCart(json);
                              window.scrollTo({ top: 0 })
                            } else {
                              throw new Error(`Couldn't clear cart #${cart.id}`);
                            }
                          });
                        }}
                      >
                        remove
                      </span>
                    </div>
                  )}
                </>
              ) : giftCard ? (
                <>
                  <div className="tw-font-styleSans tw-font-semibold tw-mb-2 tw-truncate tw-leading-none">{giftCard.name}</div>

                  <div className="tw-mb-2">Quantity: {orderItem.quantity}</div>
                  <div className="tw-mb-2">Date to send gift: {dayjs(orderItem.sendGiftDate).format(UIDateFormat)} </div>

                  {isCheckout && (
                    <div>
                      <Link href={AppRoutes.giftsSendRoute().toUrl()}>
                        <a className={`tw-text-brand-navy tw-underline tw-cursor-pointer ${styleText}`}>edit</a>
                      </Link>
                      <span className={`tw-mx-2 tw-text-brand-navy ${styleText}`}>|</span>
                      <span
                        className={`tw-text-brand-navy tw-underline tw-cursor-pointer ${styleText}`}
                        onClick={() => {
                          confirmDialog({
                            title: 'Cart',
                            body: 'Remove from cart?',
                            okText: 'Yes',
                            cancelText: 'No',
                          }).then(async () => {
                            const [json] = await clearCart({ cart_id: cart.id });
                            if (!('error' in json)) {
                              setCart(json);
                              window.scrollTo({ top: 0 })
                            } else {
                              throw new Error(`Couldn't clear cart #${cart.id}`);
                            }
                          });
                        }}
                      >
                        remove
                      </span>
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ) : option ? (
            <>
              <div className="tw-mb-1">
                <div className="tw-font-semibold tw-font-styleSans tw-text-lg md:tw-text-xl tw-leading-none md:tw-leading-none">
                  {truncate(option.name, { length: 42 })}
                </div>
              </div>

              <div className={`tw-mb-2 tw-text-brand-navy ${styleText}`}>
                {displayStartEndDate({
                  startDate: orderItem.startDate,
                  endDate: orderItem.endDate,
                })}
                {orderItem.startTime &&
                  orderItem.endTime ?
                    `, ${dayjs.utc(orderItem.startTime).format(UITimeFormat)}` : ''}{' | '}
                {pluralize('person', orderItem.totalQuantity, true)}
              </div>

              {renderAfterQuantity ? renderAfterQuantity() : ''}

              {isCheckout && (
                <div>
                  <Link
                    href={AppRoutes.experienceRoute({ id: option.slugCode }, { gdc: giftExperienceCode?.code }).toUrl()} target="_blank"
                    rel="noreferrer"
                  >
                    <a className={`tw-text-brand-navy tw-underline tw-cursor-pointer ${styleText}`}>
                      edit
                    </a>
                  </Link>
                  <span className={`tw-mx-2 tw-text-brand-navy ${styleText}`}>|</span>
                  <span
                    className={`tw-text-brand-navy tw-underline tw-cursor-pointer ${styleText}`}
                    onClick={() => {
                      confirmDialog({
                        title: 'Cart',
                        body: 'Remove from cart?',
                        okText: 'Yes',
                        cancelText: 'No',
                      }).then(async () => {
                        const [json] = await clearCart({ cart_id: cart.id });
                        if (!('error' in json)) {
                          setCart(json);
                          window.scrollTo({ top: 0 })
                        } else {
                          throw new Error(`Couldn't clear cart #${cart.id}`);
                        }
                      });
                    }}
                  >
                    remove
                  </span>
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </div>

        {children}
      </div>
    </div>
  );
};

export default PurchaseItem;
