import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ControlHeaderPageNavContext } from 'src/contexts/ControlHeaderPageNavProvider';
import Link from 'next/link';
import { default as NextImage } from 'next/future/image';
import { Country, SearchResult } from 'src/types';
import SearchSvg from 'public/images/svg_icons/search.svg';
import User1Svg from 'public/images/svg_icons/user-1.svg';
import Calendar1Svg from 'public/images/svg_icons/calendar-1.svg';
import { AppRoutes } from 'src/lib/routes';
import pluralize from 'pluralize';
import classNames from 'classnames';
import flatpickr from 'flatpickr';
import Stepper from 'src/components/Stepper';
import useRouter from 'next/router';
import { debounce, truncate, escapeRegExp, sortBy } from 'lodash-es';
import ApiClient from 'src/lib/api_client';
import useSearchBarState from 'src/hooks/useSearchBarState';
import { DBDateFormat, HEADER_HEIGHT } from 'src/lib/constants';
import dayjs from 'dayjs';
import useFilter from 'src/hooks/useFilter';
import useIsMounted from 'src/hooks/useIsMounted';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { Overlay, Popover, Modal } from 'react-bootstrap';
import useInitialData from 'src/hooks/useInitialData';
import ImageWithFallback from './ImageWithFallback';
import MapPinSvg from 'public/images/svg_icons/map-pin.svg';
import { clearSearchHistory, getSearchHistory, updateSearchHistory } from 'src/lib/search_history';
import imgDefault from 'public/images/default.jpeg';
import { FiX } from 'react-icons/fi';
import ClientOnlyPortal from 'src/components/ClientOnlyPortal';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import Highlighter from 'react-highlight-words';
import { googleNotifier } from 'src/lib/social_event_notify';
import useEnhanceApiClient from 'src/hooks/useEnhanceApiClient';
import { scrollTo } from 'src/helpers';

const CollectionResultType = ['Activity', 'Collection'];

type HeaderSearchProps = {
  hideAllPopups?: boolean;
  isMaskHidden?: boolean;
  sameAsDesktop?: boolean;
  isHeroSearch?: boolean;
  placeInputPlaceholder?: string;
};

type DatepickerProps = {
  renderSelectButton?: () => JSX.Element;
  setTouched: React.Dispatch<React.SetStateAction<{ [k: string]: boolean }>>;
};

const Datepicker: React.FC<DatepickerProps> = ({ setTouched, renderSelectButton }) => {
  const [searchBarParams, setSearchBarParams] = useSearchBarState();
  const datepickerRef = useRef<flatpickr.Instance>();
  const datepickerEleRef = useRef<HTMLDivElement>(null);

  const clearDates = () => {
    datepickerRef.current?.clear();
    setSearchBarParams((prev) => ({ ...prev, dates: [] }));
    setTouched((prev) => ({ ...prev, dates: true }));
  };

  useEffect(() => {
    if (datepickerEleRef.current) {
      const currentDay = new Date();
      datepickerRef.current = flatpickr(datepickerEleRef.current, {
        mode: 'range',
        inline: true,
        static: true,
        minDate: currentDay,
        prevArrow: '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>',
        nextArrow: '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>',
        maxDate: new Date(currentDay.getFullYear() + 1, currentDay.getMonth() + 1, 0),
        defaultDate: searchBarParams.dates.map((d) => dayjs(d).toDate()),
        dateFormat: 'd-m-Y',
        onReady: function (_, __, instance) {
          const selectEle: HTMLSelectElement | null = instance.calendarContainer.querySelector(
            '.flatpickr-monthDropdown-months'
          );
          if (selectEle) selectEle.disabled = true;
          const inputEle: HTMLInputElement | null =
            instance.calendarContainer.querySelector('.numInput');
          if (inputEle) inputEle.disabled = true;
          const arrowUpEle: HTMLElement | null =
            instance.calendarContainer.querySelector('.arrowUp');
          if (arrowUpEle) arrowUpEle.remove();
          const arrowDownEle: HTMLElement | null =
            instance.calendarContainer.querySelector('.arrowDown');
          if (arrowDownEle) arrowDownEle.remove();
        },
        onChange: function (dateObj) {
          if (dateObj.length === 0) {
            setSearchBarParams((prev) => ({ ...prev, dates: [] }));
          } else {
            setSearchBarParams((prev) => ({
              ...prev,
              dates: dateObj.map((d) => dayjs(d).format(DBDateFormat)),
            }));
          }
          setTouched((prev) => ({ ...prev, dates: true }));
        },
      });
    }
  }, [searchBarParams.dates.length]);

  return (
    <>
      <div className="flatpickr-brand flatpickr-header-date">
        <div ref={datepickerEleRef}></div>
      </div>

      <div className="tw-mt-3 tw-mb-4 tw-border-grey-lighter tw-border-t"></div>

      <div className="tw-flex tw-justify-between tw-items-center">
        <div
          className="tw-cursor-pointer tw-underline tw-font-styleSans tw-text-brand-navy"
          onClick={clearDates}
        >
          clear
        </div>

        <div>{renderSelectButton && renderSelectButton()}</div>
      </div>
    </>
  );
};

type NumGuestInputProps = {
  renderSelectButton?: () => JSX.Element;
  setTouched: React.Dispatch<React.SetStateAction<{ [k: string]: boolean }>>;
};
const NumGuestInput: React.FC<NumGuestInputProps> = ({ setTouched, renderSelectButton }) => {
  const [searchBarParams, setSearchBarParams] = useSearchBarState();

  return (
    <>
      <div className="">
        <div className="tw-font-styleSans tw-text-base tw-font-semibold tw-text-brand-navy tw-text-center tw-mb-2">Adult</div>
        <div className="tw-text-center tw-mb-6">
          <div className="tw-inline-block">
            <Stepper
              value={searchBarParams.numAdult}
              plusClass="tw-w-5 tw-h-5 tw-text-brand-navy"
              minusClass="tw-w-5 tw-h-5 tw-text-brand-navy"
              inputClass="tw-bg-brand-yellow"
              onChange={(values) => {
                setSearchBarParams((prev) => ({
                  ...prev,
                  numAdult: Number(values.value),
                  numGuest: Number(values.value || 0) + (prev.numChild || 0),
                }));
                setTouched((prev) => ({ ...prev, numGuest: true }));
              }}
              onIncrease={() => {
                setSearchBarParams((prev) => ({
                  ...prev,
                  numAdult: prev.numAdult ? prev.numAdult + 1 : 1,
                  numGuest: (prev.numAdult ? prev.numAdult + 1 : 1) + (prev.numChild || 0),
                }));
                setTouched((prev) => ({ ...prev, numGuest: true }));
              }}
              onDecrease={() => {
                setSearchBarParams((prev) => {
                  const numAdult = prev.numAdult
                    ? prev.numAdult > 1
                      ? prev.numAdult - 1
                      : null
                    : null;
                  return {
                    ...prev,
                    numAdult: numAdult,
                    numGuest:
                      typeof prev.numChild === 'number' ? (numAdult || 0) + prev.numChild : numAdult,
                  };
                });
                setTouched((prev) => ({ ...prev, numGuest: true }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="tw-font-styleSans tw-text-base tw-font-semibold tw-text-brand-navy tw-mb-2 tw-text-center">Child</div>
        <div className="tw-text-center">
          <div className="tw-inline-block">
            <Stepper
              value={searchBarParams.numChild}
              plusClass="tw-w-5 tw-h-5 tw-text-brand-navy"
              minusClass="tw-w-5 tw-h-5 tw-text-brand-navy"
              inputClass="tw-bg-brand-yellow"
              onChange={(values) => {
                setSearchBarParams((prev) => ({
                  ...prev,
                  numChild: Number(values.value),
                  numGuest: Number(values.value || 0) + (prev.numAdult || 0),
                }));
                setTouched((prev) => ({ ...prev, numGuest: true }));
              }}
              onIncrease={() => {
                setSearchBarParams((prev) => ({
                  ...prev,
                  numChild: prev.numChild ? prev.numChild + 1 : 1,
                  numGuest: (prev.numChild ? prev.numChild + 1 : 1) + (prev.numAdult || 0),
                }));
                setTouched((prev) => ({ ...prev, numGuest: true }));
              }}
              onDecrease={() => {
                setSearchBarParams((prev) => {
                  const numChild = prev.numChild
                    ? prev.numChild > 1
                      ? prev.numChild - 1
                      : null
                    : null;
                  return {
                    ...prev,
                    numChild: numChild,
                    numGuest:
                      typeof prev.numAdult === 'number' ? (numChild || 0) + prev.numAdult : numChild,
                  };
                });
                setTouched((prev) => ({ ...prev, numGuest: true }));
              }}
            />
          </div>
        </div>
      </div>
      {renderSelectButton && renderSelectButton()}
    </>
  );
};

type SearchContentProps = {
  searchResults: SearchResult;
  searchText: string;
};

const SearchContent: React.FC<SearchContentProps> = ({ searchResults, searchText }) => {
  const { countries, cities } = useInitialData();
  const { isScreenMd } = useBreakpoints();

  const [searchHistory, setSearchHistory] = useState(getSearchHistory());
  const sortedCountries = sortBy(countries, [
    (country) => (country.slug === 'singapore' ? 0 : 1),
    (country) => -country.optionsCount,
  ]);
  const sortedCities = sortBy(cities, [(city) => -city.optionsCount]).slice(0, 6);

  return (
    <>
      {!searchResults.results ? (
        <div>
          {searchHistory.length > 0 ? (
            <div className="tw-mb-5 md:tw-mb-8">
              <div className="tw-flex tw-items-center tw-justify-between tw-mb-3 md:tw-mb-4">
                <div className="tw-text-brand-navy tw-font-styleSans tw-font-semibold tw-text-lg md:tw-text-xl">
                  Search History
                </div>
                <div
                  className="tw-text-sm tw-underline tw-cursor-pointer tw-text-brand-navy tw-font-styleSans tw-font-semibold"
                  onClick={() => {
                    setSearchHistory([]);
                    clearSearchHistory();
                  }}
                >
                  clear all
                </div>
              </div>
              {searchHistory.map((text) => (
                <Link
                  key={text}
                  href={AppRoutes.searchRoute({}, { record_id: text, record_type: 'Fts' }).toUrl()}
                >
                  <a className="tw-bg-white tw-rounded-4xl tw-px-3 tw-py-2 tw-text-brand-navy tw-mr-3 tw-mb-2 tw-text-sm tw-inline-block">
                    {text}
                  </a>
                </Link>
              ))}
            </div>
          ) : null}

          {sortedCountries.length > 0 ? (
            <div className="tw-hidden md:tw-block tw-mb-5 md:tw-mb-8">
              <div className="tw-font-semibold tw-text-lg md:tw-text-xl tw-mb-3 md:tw-mb-4 tw-font-styleSans tw-text-brand-navy">
                Top Countries
              </div>
              <div className="tw-flex tw-flex-nowrap tw-overflow-x-auto tw--mx-1.5 custom-scrollbar">
                {sortedCountries.map((country) => (
                  <Link
                    key={country.id}
                    href={
                      country.slug === 'singapore'
                        ? AppRoutes.cityRoute({ id: 'singapore' }).toUrl()
                        : AppRoutes.countryRoute({ id: country.slug }).toUrl()
                    }
                  >
                    <a className="tw-text-black">
                      <div className="tw-px-2">
                        <div className="tw-border tw-border-brand-green tw-rounded-full">
                          <ImageWithFallback
                            record={country.image}
                            imgField="webpSmUrl"
                            alt={country.name}
                            fill
                            className="tw-static tw-object-cover tw-rounded-full tw-w-16 md:tw-w-20 tw-h-16 md:tw-h-20"
                          />
                        </div>
                        <div className="tw-flex tw-items-center tw-justify-center tw-mt-2">
                          <span className="tw-text-base tw-font-styleSans tw-font-semibold tw-text-brand-navy">
                            {country.name}
                          </span>
                        </div>
                      </div>
                    </a>
                  </Link>
                ))}
              </div>
            </div>
          ) : null}

          {sortedCities.length > 0 ? (
            <div className="">
              <div className="tw-font-semibold tw-text-lg md:tw-text-xl tw-mb-2 md:tw-mb-3 tw-font-styleSans tw-text-brand-navy">
                Top Cities
              </div>
              <div className="tw-flex tw-flex-wrap tw--mx-1.5">
                {sortedCities.map((city) => (
                  <div key={city.id} className="tw-w-1/2 md:tw-w-1/3 tw-p-1.5">
                    <Link href={AppRoutes.cityRoute({ id: city.slug }).toUrl()}>
                      <a className="tw-block">
                        <div
                          className="tw-text-black tw-relative tw-h-32 tw-block tw-rounded-lg tw-border-2 tw-border-brand-green tw-border-solid tw-bg-no-repeat tw-bg-cover"
                          style={{ backgroundImage: `url(${city.image?.webpMdUrl})` }}
                        ></div>
                        <div className="tw-text-center tw-font-styleSans tw-font-semibold tw-mt-2 tw-text-brand-navy tw-text-sm md:tw-text-base">
                          {city.name}
                        </div>
                      </a>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {searchResults.results ? (
        searchResults.results.length > 0 || searchResults.countriesCities.length > 0 ? (
          <div>
            {searchResults.countriesCities.map((item) => (
              <Link
                key={item.id}
                href={
                  item.type == 'City'
                    ? AppRoutes.cityRoute({ id: item.slug }).toUrl()
                    : AppRoutes.countryRoute({ id: item.slug }).toUrl()
                }
              >
                <a className="search-box__result-item tw-justify-between">
                  <div className="tw-flex tw-items-center">
                    <NextImage
                      src={
                        (item.type == 'City'
                          ? cities.find((o) => o.id === Number(item.id))
                          : countries.find((o) => o.id === Number(item.id))
                        )?.image?.webpSmUrl || imgDefault
                      }
                      fill
                      className="tw-object-cover tw-w-16 tw-h-16 tw-mr-5 tw-inline-block tw-static tw-rounded-full"
                      alt={item.name}
                      unoptimized={true}
                    />
                    <div>
                      <Highlighter
                        searchWords={escapeRegExp(searchText).split(' ')}
                        textToHighlight={item.name}
                        className="search-box__result-item-title"
                        highlightClassName="tw-text-orange"
                      />
                      <div className="search-box__result-item-subtitle">{item.type}</div>
                    </div>
                  </div>
                </a>
              </Link>
            ))}
            {searchResults.results.map((result) => (
              <Link
                key={result.id}
                href={
                  result.type === 'Collection'
                    ? AppRoutes.collectionRoute({ id: result.slugCode }).toPath()
                    : AppRoutes.experienceRoute({ id: result.slugCode }).toPath()
                }
              >
                <a className="search-box__result-item tw-justify-between">
                  {CollectionResultType.includes(result.type) ? (
                    <>
                      <div className="tw-flex tw-items-center">
                        <NextImage
                          src={result.imageUrl}
                          fill
                          className="tw-object-cover tw-w-16 tw-h-16 tw-mr-5 tw-inline-block tw-static tw-rounded-lg"
                          alt={result.name}
                          unoptimized={true}
                        />
                        <div>
                          <Highlighter
                            searchWords={escapeRegExp(searchText).split(' ')}
                            textToHighlight={
                              isScreenMd
                                ? result.name
                                : truncate(result.name, { length: 38, omission: '...' })
                            }
                            className="search-box__result-item-title"
                            highlightClassName="tw-text-orange"
                          />
                          <div className="search-box__result-item-subtitle">
                            Collection · {result.cityName}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tw-flex tw-items-center">
                        <NextImage
                          src={result.imageUrl}
                          fill
                          className="tw-object-cover tw-w-16 tw-h-16 tw-mr-5 tw-inline-block tw-static tw-rounded-lg"
                          alt={result.name}
                          unoptimized={true}
                        />
                        <div>
                          <Highlighter
                            searchWords={escapeRegExp(searchText).split(' ')}
                            textToHighlight={
                              isScreenMd
                                ? result.name
                                : truncate(result.name, { length: 38, omission: '...' })
                            }
                            className="search-box__result-item-title"
                            highlightClassName="tw-text-orange"
                          />
                          <div className="search-box__result-item-subtitle">
                            Activity · {result.cityName}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </a>
              </Link>
            ))}
          </div>
        ) : (
          <div>Oops! No results found.</div>
        )
      ) : null}
    </>
  );
};

const HeaderSearch: React.FC<HeaderSearchProps> = ({ hideAllPopups, isMaskHidden, sameAsDesktop, isHeroSearch, placeInputPlaceholder = 'Place or activity' }) => {
  const searchContainerRef = useRef<HTMLDivElement>(null)
  const apiSearch = useEnhanceApiClient(ApiClient.search)
  const isMounted = useIsMounted();
  const { showHeader } = React.useContext(ControlHeaderPageNavContext);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const startDateInputRef = useRef<HTMLInputElement | null>(null);
  const startDateInput2Ref = useRef<HTMLInputElement | null>(null);
  const numGuestInputRef = useRef<HTMLInputElement | null>(null);
  const numGuestInput2Ref = useRef<HTMLInputElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const { isScreenMd } = useBreakpoints();
  const [touched, setTouched] = useState<{ [k: string]: boolean }>({});
  const [searchBarParams, setSearchBarParams] = useSearchBarState();
  const [isSearchInputFocus, setIsSearchInputFocus] = useState(false);
  const router = useRouter;
  const [searchResults, setSearchResults] = useState<SearchResult>({} as SearchResult);
  const [searchText, setSearchText] = useState<string>('');
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showMobileSearchPopup, setShowMobileSearchPopup] = useState(false);
  const [showNumGuestPopup, setShowNumGuestPopup] = useState(false);
  const [showDatePopup, setShowDatePopup] = useState(false);
  const [calculatedMaskHeight, setCalculatedMaskHeight] = useState('100vh');
  const { filterRecord } = useFilter();

  const enableModalScroll = () => {
    if (modalRef.current) {
      const modalBody =
        typeof modalRef.current.querySelector === 'function'
          ? modalRef.current.querySelector('.modal-body')
          : undefined;
      if (modalBody) {
        enableBodyScroll(modalBody);
      }
    }
  };

  const disableModalScroll = () => {
    if (modalRef.current) {
      const modalBody =
        typeof modalRef.current.querySelector === 'function'
          ? modalRef.current.querySelector('.modal-body')
          : undefined;
      if (modalBody) {
        disableBodyScroll(modalBody);
      }
    }
  };

  const search = useCallback(
    debounce(async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.target instanceof HTMLInputElement) {
        const text = e.target.value.trim();
        if (!text) return;

        const [json] = await apiSearch({ q: text });
        if (!('error' in json)) {
          googleNotifier.sendSearch(text);

          if (e.key === 'Enter') {
            const countryCity = json.countriesCities[0]
            if (countryCity) {
              const countryCityRoute = countryCity.type === 'City' ? AppRoutes.cityRoute({ id: countryCity.slug }) : AppRoutes.countryRoute({ id: countryCity.slug })
              router.push(countryCityRoute.toPath());
            } else {
              router.push(AppRoutes.searchRoute({}, { record_id: text, record_type: 'Fts' }).toPath());
            }
            updateSearchHistory(text);
          } else {
            setSearchResults(json);
          }
        } else {
          throw new Error(`Couldn't search with ${e.target.value}`);
        }
      }
    }, 400),
    [filterRecord?.name, touched.searchText]
  );

  const filter = useCallback(() => {
    if (searchText && searchText !== filterRecord?.name && searchText.trim() !== '') {
      setTouched({});
      const countryCity = searchResults.countriesCities[0]
      if (countryCity) {
        const countryCityRoute = countryCity.type === 'City' ? AppRoutes.cityRoute({ id: countryCity.slug }) : AppRoutes.countryRoute({ id: countryCity.slug })
        router.push(countryCityRoute.toPath());
      } else {
        router.push(
          AppRoutes.searchRoute({}, { record_id: searchText, record_type: 'Fts' }).toPath()
        );
      }
      updateSearchHistory(searchText);
    } else if (filterRecord?.id) {
      setTouched({});
      router.push(
        AppRoutes.searchRoute(
          {},
          { record_id: filterRecord.id, record_type: filterRecord.type }
        ).toPath()
      );
    }
  }, [
    searchText,
    router,
    filterRecord?.id,
    filterRecord?.type,
    filterRecord?.name,
    searchResults,
    JSON.stringify(searchBarParams),
  ]);

  useEffect(() => {
    if (
      isScreenMd &&
      router.pathname !== '/search' &&
      !showNumGuestPopup &&
      !showDatePopup &&
      (touched.dates || touched.numGuest)
    ) {
      filter();
    }
  }, [
    showNumGuestPopup,
    showDatePopup,
    touched.dates,
    touched.numGuest,
    filter,
    isMounted ? router.pathname : 0,
    isScreenMd,
  ]);

  useEffect(() => {
    if (router.isReady) {
      router.events.on('routeChangeStart', () => {
        setSearchResults({} as SearchResult);
        setSearchText('');
      });

      router.events.on('routeChangeComplete', () => {
        setShowSearchPopup(false);
        setShowDatePopup(false);
        setShowNumGuestPopup(false);
        setShowMobileSearchPopup(false);
        enableModalScroll();
      });
    }
  }, [router]);

  useEffect(() => {
    setSearchText(filterRecord?.name || '');
  }, [isMounted ? router.pathname : 0, filterRecord?.name]);

  useLayoutEffect(() => {
    setCalculatedMaskHeight(`calc(100vh - ${filterRecord?.id ? 143 : 78}px)`);
  }, [filterRecord?.id ? 1 : 0]);

  useEffect(() => {
    if (hideAllPopups) {
      setShowSearchPopup(false);
      setShowDatePopup(false);
      setShowNumGuestPopup(false);
      setShowMobileSearchPopup(false);
    }
  }, [hideAllPopups])

  return (
    <>
      <div ref={searchContainerRef} className="nav-item position-relative lg:tw-flex-grow tw-font-sans">
        <div
          className={classNames(
            'tw-bg-grey-lightest tw-rounded-3xl tw-py-1.5 tw-px-2 tw-items-center tw-justify-center tw-border tw-border-transparent tw-w-full',
            {
              'tw-border-orange': showSearchPopup || showDatePopup || showNumGuestPopup,
              'tw-flex': sameAsDesktop,
              'tw-hidden lg:tw-flex': !sameAsDesktop,
            }
          )}
        >
          <div className="tw-flex tw-flex-nowrap tw-flex-grow">
            <div className="tw-relative tw-flex tw-flex-grow">
              <input
                ref={searchInputRef}
                name="q"
                placeholder={placeInputPlaceholder}
                className={classNames(
                  'form-control tw-w-full md:tw-w-auto tw-h-5 tw-py-0 tw-pr-2 md:tw-pr-10 tw-bg-transparent tw-text-sm md:tw-text-base tw-rounded-tl tw-flex-grow tw-rounded-bl tw-border-0',
                  {
                    'tw-font-semibold': !!searchText,
                    'tw-text-center md:tw-text-left': isHeroSearch,
                    'tw-pl-8 md:tw-pl-3': isHeroSearch
                  }
                )}
                autoComplete="off"
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  if (isScreenMd) {
                    if (searchContainerRef.current) {
                      scrollTo(searchContainerRef.current, -(HEADER_HEIGHT + 16))
                    }
                    setTimeout(() => {
                      setShowSearchPopup(true);
                    }, 0);
                  } else {
                    setShowMobileSearchPopup(true);
                    disableModalScroll();
                    e.currentTarget.blur();
                  }
                }}
                onKeyPress={search}
                onKeyDown={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setSearchText(value);
                  setTouched((prev) => ({ ...prev, text: true }));
                  if (!value) {
                    setSearchResults({} as SearchResult);
                  }
                }}
                value={searchText}
              />
              {searchText ? (
                <div
                  className="tw-hidden lg:tw-block tw-rounded-full tw-border tw-border-grey-lighter tw-h-7 tw-w-7 tw-p-1 tw-text-center tw-bg-white tw-absolute tw--top-1 tw-right-2 tw-leading-none tw-cursor-pointer"
                  onClick={() => {
                    setSearchText('');
                    setTouched((prev) => ({ ...prev, text: true }));
                    setSearchResults({} as SearchResult);
                  }}
                >
                  <FiX size={16} className="tw-h-4 tw-w-4" />
                </div>
              ) : null}
            </div>
            {!isHeroSearch ? (
              <div className="tw-flex tw-flex-shrink-0">
                <input
                  ref={startDateInput2Ref}
                  name="start_date"
                  placeholder="Date"
                  className={classNames(
                    'form-control tw-h-5 tw-py-0 tw-bg-transparent tw-text-sm md:tw-text-base tw-w-20 md:tw-w-36 tw-flex-grow-0 tw-border-0 tw-border-l tw-rounded-none tw-border-grey-lighter hover:tw-border-grey-lighter',
                    {
                      'tw-font-semibold': searchBarParams.dates && searchBarParams.dates.length > 0,
                    }
                  )}
                  value={searchBarParams.dates.map((d) => dayjs(d).format('MMM DD')).join(' - ')}
                  readOnly
                  onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    if (isScreenMd) {
                      setTimeout(() => {
                        setShowDatePopup(true);
                      });
                    } else {
                      setShowMobileSearchPopup(true);
                      disableModalScroll();
                      e.currentTarget.blur();
                    }
                  }}
                />
                <input
                  ref={numGuestInput2Ref}
                  name="num_guest"
                  placeholder="Guests"
                  className={classNames(
                    'form-control tw-h-5 tw-py-0 tw-bg-transparent tw-text-sm md:tw-text-base tw-w-20 md:tw-w-24 tw-flex-shrink-0 tw-flex-grow-0 tw-border-0 tw-border-l tw-rounded-none tw-border-grey-lighter hover:tw-border-grey-lighter',
                    {
                      'tw-font-semibold': typeof searchBarParams.numGuest === 'number',
                    }
                  )}
                  readOnly
                  value={
                    typeof searchBarParams.numGuest === 'number'
                      ? pluralize('guest', searchBarParams.numGuest, true)
                      : ''
                  }
                  onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    if (isScreenMd) {
                      setTimeout(() => {
                        setShowNumGuestPopup(true);
                      });
                    } else {
                      setShowMobileSearchPopup(true);
                      disableModalScroll();
                      e.currentTarget.blur();
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
          <button
            type="button"
            className="tw-w-6 tw-h-6 tw-px-0 tw-text-center tw-rounded-full tw-bg-orange tw-outline-none tw-flex-shrink-0"
            onClick={() => {
              if (isScreenMd) {
                filter();
              } else {
                setShowMobileSearchPopup(true);
                disableModalScroll();
              }
            }}
          >
            <SearchSvg className="tw-text-white tw-w-4 tw-h-4 tw-mb-1" />
          </button>
        </div>

        <div
          className={classNames({
            'tw-block lg:tw-hidden': !sameAsDesktop,
            'tw-hidden': sameAsDesktop,
          })}
        >
          <button
            type="button"
            className="tw-w-6 tw-h-6 tw-px-0 tw-text-center tw-rounded-full tw-bg-orange tw-outline-none tw-order-first md:tw-order-last tw-flex-shrink-0"
            onClick={() => {
              setShowMobileSearchPopup(true);
              disableModalScroll();
            }}
          >
            <SearchSvg className="tw-text-white tw-w-4 tw-h-4 tw-mb-1" />
          </button>
        </div>

        <Modal ref={modalRef} show={showMobileSearchPopup} className="modal-styled">
          <Modal.Header className="tw-px-4">
            <div className="tw-flex tw-justify-between tw-w-full">
              <div className="tw-font-semibold tw-font-styleSans tw-text-brand-navy tw-text-xl">
                Search
              </div>
              <div
                onClick={() => {
                  setShowMobileSearchPopup(false);
                  enableModalScroll();
                }}
              >
                <FiX size={24} className="tw-text-brand-navy" />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="tw-relative tw-mb-2" onClick={() => {}}>
              <input
                type="text"
                className={classNames('form-control tw-text-sm md:tw-text-base tw-rounded-4xl')}
                placeholder={`Place or activity`}
                onFocus={() => setIsSearchInputFocus(true)}
                onBlur={() => setIsSearchInputFocus(false)}
                onKeyPress={search}
                onKeyDown={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setSearchText(value);
                  setTouched((prev) => ({ ...prev, text: true }));
                  if (!value) {
                    setSearchResults({} as SearchResult);
                  }
                }}
                value={searchText}
              />
              {searchText ? (
                <div
                  className="tw-block lg:tw-hidden tw-rounded-full tw-border tw-border-grey-lighter tw-p-1 tw-bg-white tw-absolute tw-z-10 tw-top-1.5 tw-right-2 tw-leading-none tw-cursor-pointer"
                  onClick={() => {
                    setSearchText('');
                    setTouched((prev) => ({ ...prev, text: true }));
                    setSearchResults({} as SearchResult);
                  }}
                >
                  <FiX size={16} className="tw-h-4 tw-w-4" />
                </div>
              ) : null}
            </div>
            <div className="tw-mb-2">
              <input
                type="text"
                className={classNames('form-control tw-text-sm md:tw-text-base tw-rounded-4xl')}
                placeholder="Anytime"
                value={searchBarParams.dates.map((d) => dayjs(d).format('MMM DD')).join(' - ')}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  if (e.target instanceof HTMLInputElement) {
                    e.target.blur();
                  }
                  setShowDatePopup(true);
                }}
              />

              <Modal
                show={showDatePopup && !isScreenMd}
                className="tw-z-1300"
                dialogClassName="modal-dialog-centered"
                onHide={() => setShowDatePopup(false)}
              >
                <Modal.Body>
                  <Datepicker
                    renderSelectButton={() => (
                      <div className="tw-flex tw-justify-center">
                        <button
                          className="btn btn-primary tw-text-sm"
                          onClick={() => setShowDatePopup(false)}
                        >
                          Done
                        </button>
                      </div>
                    )}
                    setTouched={setTouched}
                  />
                </Modal.Body>
              </Modal>
            </div>
            <div>
              <input
                ref={numGuestInputRef}
                type="text"
                className={classNames('form-control tw-text-sm md:tw-text-base tw-rounded-4xl')}
                value={
                  typeof searchBarParams.numGuest === 'number'
                    ? pluralize('guest', searchBarParams.numGuest, true)
                    : ''
                }
                placeholder="Guests"
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  if (e.target instanceof HTMLInputElement) {
                    e.target.blur();
                  }
                  setShowNumGuestPopup(true);
                }}
              />
              <Overlay
                target={numGuestInputRef}
                show={showNumGuestPopup && !isScreenMd}
                placement="bottom"
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 4],
                      },
                    },
                  ],
                }}
              >
                <Popover
                  id={`popover-num-guest`}
                  className={`tw-max-w-sm popover-no-arrow tw-font-sans tw-bg-brand-yellow tw-rounded-lg tw-p-3 tw-border-0`}
                  style={{ width: 'calc(100vw - 36px)' }}
                >
                  <Popover.Content className="">
                    <NumGuestInput
                      renderSelectButton={() => (
                        <div className="tw-flex tw-justify-center tw-mt-4">
                          <button
                            className="btn btn-primary tw-text-sm"
                            onClick={() => setShowNumGuestPopup(false)}
                          >
                            Done
                          </button>
                        </div>
                      )}
                      setTouched={setTouched}
                    />
                  </Popover.Content>
                </Popover>
              </Overlay>
            </div>

            <div className="tw-mt-5">
              <SearchContent searchResults={searchResults} searchText={searchText} />
            </div>
          </Modal.Body>
          <Modal.Footer className="tw-px-4">
            <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
              <span
                className="tw-lowercase tw-text-sm tw-text-brand-navy tw-underline tw-cursor-pointer tw-font-styleSans tw-font-semibold"
                onClick={() => {
                  setSearchText('');
                  setSearchResults({} as SearchResult);
                  setSearchBarParams({
                    qs: '',
                    numGuest: null,
                    numAdult: null,
                    numChild: null,
                    dates: [],
                  });
                }}
              >
                Clear All
              </span>
              <button
                className="btn btn-primary tw-text-sm"
                onClick={() => {
                  filter();
                }}
              >
                Search
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Overlay
          rootClose={true}
          target={numGuestInput2Ref}
          show={showNumGuestPopup && isScreenMd && showHeader}
          placement="bottom"
          onHide={() => {
            setShowNumGuestPopup(false);
          }}
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 18],
                },
              },
            ],
          }}
        >
          <Popover
            id={`popover-search-num-guest`}
            className={`tw-max-w-sm tw-w-52 popover-no-arrow tw-font-sans tw-bg-brand-yellow tw-rounded-lg tw-px-8 tw-py-6 tw-border-0`}
          >
            <Popover.Content className="">
              <NumGuestInput setTouched={setTouched} />
            </Popover.Content>
          </Popover>
        </Overlay>

        <Overlay
          rootClose={true}
          target={startDateInput2Ref}
          show={showDatePopup && isScreenMd && showHeader}
          placement="bottom-start"
          onHide={() => {
            setShowDatePopup(false);
          }}
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-246, 18],
                },
              },
            ],
          }}
        >
          <Popover
            id={`popover-search-date`}
            className={`tw-w-full popover-no-arrow tw-font-sans tw-overflow-y-auto tw-border-0 tw-bg-brand-yellow tw-rounded-lg tw-p-8`}
            style={{ maxWidth: '526px' }}
          >
            <Popover.Content className="tw-p-0">
              <Datepicker setTouched={setTouched} />
            </Popover.Content>
          </Popover>
        </Overlay>

        <Overlay
          rootClose={true}
          target={searchInputRef}
          show={showSearchPopup && isScreenMd && showHeader}
          placement="bottom-start"
          onHide={() => {
            setShowSearchPopup(false);
          }}
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-8, 18],
                },
              },
            ],
          }}
        >
          <Popover
            id={`popover-search-text`}
            className={`tw-w-full popover-no-arrow tw-font-sans tw-overflow-y-auto tw-border-0 tw-bg-brand-yellow tw-rounded-lg tw-p-8`}
            style={{ maxHeight: 'calc(100vh - 104px)', maxWidth: '565px' }}
          >
            <Popover.Content>
              <SearchContent searchResults={searchResults} searchText={searchText} />
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>

      <ClientOnlyPortal selector="#bottom-body">
        {!isScreenMd ? (
          <div
            className={classNames(
              'tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen tw-h-screen',
              { 'tw-hidden': !showDatePopup && !showNumGuestPopup }
            )}
            style={{ zIndex: '1051' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
      <ClientOnlyPortal selector="#bottom-body">
        {isScreenMd ? (
          <div
            className={classNames(
              'tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen tw-z-101',
              {
                'tw-hidden':
                  isMaskHidden ||
                  (!showSearchPopup && !showDatePopup && !showNumGuestPopup) ||
                  !showHeader,
              }
            )}
            style={{ height: 'calc(100vh - 72px)' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
    </>
  );
};

export default HeaderSearch;
