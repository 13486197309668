import React from 'react'
import useCurrencyExchange from 'src/hooks/useCurrencyExchange'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { TMoney, Currency } from 'src/types'
import currencies from 'src/data/currencies'

type Props = {
  amount: TMoney;
  toCurrency?: Currency;
  className?: string;
} & NumericFormatProps

const Money: React.FC<Props> = ({ amount, toCurrency, className = '', ...rest }) => {
  const currencyExchange = useCurrencyExchange()
  let actualAmount = currencyExchange({ amount: amount, toCurrency: toCurrency })
  let currentCurrency = currencies.find(o => o.code === actualAmount.currency)

  return currentCurrency ? (
    <NumericFormat
      value={parseFloat(String(actualAmount.amount))}
      displayType="text"
      className={className}
      thousandSeparator={currentCurrency.thousandsSeparator}
      decimalSeparator={currentCurrency.decimalMark}
      decimalScale={Number(currentCurrency.exponent)}
      prefix={currentCurrency.prefix ? currentCurrency.prefix : ''}
      suffix={currentCurrency.suffix ? currentCurrency.suffix : ''}
      {...rest}
    />
  ) : <></>
}

export default Money
