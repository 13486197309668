import React, { useState } from 'react';

type ControlHeaderPageNavProps = {
  showHeader: boolean;
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ControlHeaderPageNavContext = React.createContext<ControlHeaderPageNavProps>({
  showHeader: true,
  setShowHeader: () => {},
});

const ControlHeaderPageNavProvider: React.FC = ({ children }) => {
  const [showHeader, setShowHeader] = useState<boolean>(true);
  return (
    <ControlHeaderPageNavContext.Provider value={{ showHeader, setShowHeader }}>
      {children}
    </ControlHeaderPageNavContext.Provider>
  );
};

export default ControlHeaderPageNavProvider;
