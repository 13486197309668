import React from 'react';
import { InitialData } from 'src/types';
import useQuery from 'src/hooks/useQuery';
import { ApiRoutes } from 'src/lib/routes';

const DEFAULT_INITIAL_DATA: InitialData = {
  countries: [],
  cities: [],
  currentGeolocation: {
    countryCode: null
  },
  tags: [],
  exchangeRates: [],
  checkoutSupportedCurrencies: [],
  settings: {}
};

export const InitialDataContext = React.createContext<InitialData>(DEFAULT_INITIAL_DATA);

const InitialDataProvider: React.FC = ({ children }) => {
  const result = useQuery<InitialData>(ApiRoutes.apiInitialDataRoute().toUrl());
  const initialData = (result.data || DEFAULT_INITIAL_DATA) as InitialData;
  return <InitialDataContext.Provider value={initialData}>{children}</InitialDataContext.Provider>;
};

export default InitialDataProvider;
