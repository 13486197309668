import { Price, Option, EnumOptionKind, EnumPriceUnit, EnumAdvancedGroupPriceType } from 'src/types';
import { compact, max } from 'lodash-es'

export const canShowAverageAdvancedGroupPrice = (option: Option) => {
  return (
    !option.yachtTrip &&
    option.kind === EnumOptionKind.shortAdventure &&
    option.priceUnit === EnumPriceUnit.perGroup &&
    option.groupPriceType === EnumAdvancedGroupPriceType.pricePerGroupWithAdditionalPaxAddon
  );
};

export const getMaxQuantityFromPrices = (prices: Price[]) => {
  const quantities = compact(prices.map((o) => (o.maxQtyForPrice || o.minQtyForPrice)))
  return max(quantities) || 0
}
